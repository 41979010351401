import { ApolloProvider } from "@apollo/client";
import { useSession } from "next-auth/client";
import React from "react";

import { BIKES, CITIES, PRICING_PACKAGES } from "../constants";
import { useApolloClient } from "../utils/apollo";

const GraphqlProvider: React.FC = ({ children }) => {
  const [session] = useSession();

  const client = useApolloClient({
    token: session?.accessToken,
    initialState: {
      ...CITIES,
      "CityDetailsWithBikes:1": {
        id: 1,
        __typename: "CityDetailsWithBikes",
        bikes: [...BIKES],
        city: {
          __ref: "City:1",
        },
      },

      "CityDetailsWithBikes:2": {
        id: 2,
        __typename: "CityDetailsWithBikes",
        bikes: [...BIKES],
        city: {
          __ref: "City:2",
        },
      },
      ROOT_QUERY: {
        __typename: "Query",
        "bikes({})": [
          {
            __ref: "CityDetailsWithBikes:1",
          },
          {
            __ref: "CityDetailsWithBikes:2",
          },
        ],
        cities: [
          {
            __ref: "City:2",
          },
          {
            __ref: "City:1",
          },
        ],
        'bikes({"cityId":1})': [
          {
            __ref: "CityDetailsWithBikes:1",
          },
        ],
        'bikes({"cityId":2})': [
          {
            __ref: "CityDetailsWithBikes:2",
          },
        ],
        ...PRICING_PACKAGES,
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphqlProvider;
