import { Styles } from "@chakra-ui/theme-tools";

import { ColourAtoms, typeForStyle } from "../DesignSystem";

const styles: Styles = {
  global: {
    body: {
      color: ColourAtoms.BB.Emerald,
      ...typeForStyle("PrimaryRegular", "Base"),
    },
  },
};
export default styles;
