import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/client";
import { FC, useEffect } from "react";

function useSentry() {
  const [session] = useSession();

  useEffect(() => {
    const user = session?.user;
    if (!user) {
      console.log("[SENTRY] Setting anon user");
      Sentry.setUser(null);
    } else {
      const sentryUser = {
        email: user.email,
        id: `${user.id}`,
        username: `${user.firstName} ${user.lastName}`,
      };
      console.log("[SENTRY] Setting user", sentryUser);
      Sentry.setUser(sentryUser);
    }
  }, [session]);
}

export const SentryUserTracking: FC = ({ children }) => {
  useSentry();
  return <>{children}</>;
};
