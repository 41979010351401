import "../style.css";

import { chakra } from "@buzzbike/ui";
import { ColourAtoms } from "@buzzbike/ui/src/DesignSystem";
import { ChakraProvider } from "@chakra-ui/react";
import { ErrorBoundary } from "@sentry/nextjs";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Provider as NextAuthProvider } from "next-auth/client";
import { NextSeo } from "next-seo";
import NextNprogress from "nextjs-progressbar";
import React from "react";

import ErrorFallback from "components/ErrorFallback";

import Fonts from "../components/fonts";
import GraphqlProvider from "../components/GraphqlProvider";
import { SegmentUserTracking } from "../hooks/useSegment";
import { SentryUserTracking } from "../hooks/useSentry";

function RideApp({ Component, pageProps }: AppProps) {
  return (
    <NextAuthProvider session={pageProps.session}>
      <GraphqlProvider>
        <ChakraProvider theme={chakra.theme}>
          <SegmentUserTracking
            writeKey={process.env.NEXT_PUBLIC_SEGMENT_API_KEY || ""}
          >
            <SentryUserTracking>
              <NextSeo title="Buzzbike" noindex nofollow />
              <Fonts />
              <NextNprogress
                color={ColourAtoms.BB.Pink}
                startPosition={0.3}
                stopDelayMs={200}
                height={3}
                showOnShallow={true}
                options={{ showSpinner: false }}
              />
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
                />
                <meta
                  name="facebook-domain-verification"
                  content="x7ion5fytnwuc6qej5nyxpgbqxcis4"
                />
              </Head>
              <ErrorBoundary fallback={ErrorFallback}>
                <Component {...pageProps} />
              </ErrorBoundary>
            </SentryUserTracking>
          </SegmentUserTracking>
        </ChakraProvider>
      </GraphqlProvider>
    </NextAuthProvider>
  );
}

export default RideApp;
