import { merge } from "lodash";

import { typeForStyle } from "../../DesignSystem";
import Input from "./input";

const Select = {
  baseStyle: {
    field: {
      ...typeForStyle("PrimaryRegular", "Base"),
    },
    icon: {
      fontSize: "1.5rem",
      insetEnd: "1rem",
    },
  },
  sizes: merge({}, Input.sizes, {
    xs: {
      icon: {
        insetEnd: "0.25rem",
      },
    },
  }),
};
export default Select;
