import { ColourAtoms, typeForStyle } from "../../DesignSystem";

const Radio = {
  baseStyle: {
    label: { ml: "1", color: ColourAtoms.Raw.Turquoise._600 },
  },
  sizes: {
    base: {
      control: { w: 5, h: 5 },
      label: { ...typeForStyle("PrimaryMedium", "Sm") },
    },
  },
  variants: {
    emerald: {
      label: {
        color: ColourAtoms.BB.Emerald,
      },
    },
  },
  defaultProps: {
    size: "base",
    colorScheme: "cerise",
  },
};
export default Radio;
