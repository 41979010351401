import { ColourAtoms, ConvertShadeName } from "../../DesignSystem";

const convertShade = <Shade extends string>(
  shadeName: Shade
): ConvertShadeName<Shade> => {
  return `${Number.parseInt(shadeName.replace("_", ""))}` as ConvertShadeName<
    typeof shadeName
  >;
};

type ChakraShadeMap = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

const convertShadeMap = (rawShadeMap: {
  [shade: string]: string;
}): ChakraShadeMap => {
  const entries = Object.entries(rawShadeMap).map(([shadeName, colour]) => {
    return [convertShade(shadeName), colour];
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chakraMap = {} as any;

  entries.map(([chakraShadeName, colour]) => {
    chakraMap[chakraShadeName] = colour;
  });

  return chakraMap;
};

const colors = {
  cerise: convertShadeMap(ColourAtoms.Raw.Cerise),
  mango: convertShadeMap(ColourAtoms.Raw.Mango),
  carrot: convertShadeMap(ColourAtoms.Raw.Carrot),
  sunglow: convertShadeMap(ColourAtoms.Raw.Sunglow),
  olive: convertShadeMap(ColourAtoms.Raw.Olive),
  shamrock: convertShadeMap(ColourAtoms.Raw.Shamrock),
  turquoise: convertShadeMap(ColourAtoms.Raw.Turquoise),
  pacific: convertShadeMap(ColourAtoms.Raw.Pacific),
  denim: convertShadeMap(ColourAtoms.Raw.Denim),
  royalblue: convertShadeMap(ColourAtoms.Raw.Royalblue),
  indigo: convertShadeMap(ColourAtoms.Raw.Indigo),
  flamingo: convertShadeMap(ColourAtoms.Raw.Flamingo),
  gray: convertShadeMap(ColourAtoms.Raw.Gray),
  red: convertShadeMap(ColourAtoms.Raw.Cerise),
};

export default colors;
