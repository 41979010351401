import { ColourAtoms, JZ, typeForStyle } from "../../DesignSystem";

const Checkbox = {
  baseStyle: {
    control: { alignSelf: "flex-start", mt: 1 },
    label: {
      color: ColourAtoms.Raw.Turquoise._600,
      ml: "4",
    },
  },
  sizes: {
    sm: {
      label: { ...typeForStyle("PrimaryMedium", "Sm") },
      icon: { fontSize: "0.5rem" },
    },
    md: {
      label: { ...typeForStyle("PrimaryMedium", "Base") },
      icon: { fontSize: "0.5rem" },
    },
    lg: {
      label: { ...typeForStyle("PrimaryRegular", "Lg") },
      icon: { fontSize: "0.5rem" },
    },
  },
  variants: {
    big: {
      control: {
        boxSize: 8,
        mt: 0,
        rounded: 4,
        _invalid: {
          borderColor: JZ.Negative,
        },
      },
      icon: { fontSize: "1rem" },
      label: {
        ...JZ["Web/Caption/18 Med"],
        color: JZ.BuzzBlack,
      },
    },
  },
  defaultProps: {
    colorScheme: "cerise",
  },
};
export default Checkbox;
