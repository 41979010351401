import { Global } from "@emotion/react";
import { CustomFontSource } from "@stripe/stripe-js";

export const stripeFonts: CustomFontSource[] = [
  {
    family: "Neufile Grotesk Medium",
    style: "normal",
    display: "swap",
    weight: "600",
    src: "url(/fonts/Neufile_Grotesk/Neufile-Grotesk-Medium.otf)",
  },
];

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Neufile Grotesk Light';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/Neufile_Grotesk/Neufile-Grotesk-Light.otf');
      }
      @font-face {
        font-family: 'Neufile Grotesk Regular';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/fonts/Neufile_Grotesk/Neufile-Grotesk-Regular.otf');
      }

      @font-face {
        font-family: 'Neufile Grotesk Medium';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/fonts/Neufile_Grotesk/Neufile-Grotesk-Medium.otf');
      }

      @font-face {
        font-family: 'Neufile Grotesk Semibold';
        font-style: normal;
        font-display: swap;
        src: url('/fonts/Neufile_Grotesk/Neufile-Grotesk-SemiBold.ttf');
      }

      @font-face {
        font-family: 'GT Pressura Pro';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/GT_Pressura/GT-Pressura-Pro-Bold.otf');
      }

      @font-face {
        font-family: 'GT Pressura Regular';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/GT_Pressura/GT-Pressura-Pro-Regular.otf');
      }
       
      @font-face {
        font-family: 'GT Pressura Light';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/GT_Pressura/GT-Pressura-Light.otf');
      }

       @font-face {
        font-family: 'Cindie2';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/Cindie2/Cindie2.otf');
      }
      `}
  />
);
export default Fonts;
