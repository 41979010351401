import { ColourAtoms, typeForStyle } from "../../DesignSystem";

const Text = {
  sizes: {
    xs: {
      ...typeForStyle("PrimaryRegular", "Xs"),
    },
    sm: {
      ...typeForStyle("PrimaryRegular", "Sm"),
    },
    md: {
      ...typeForStyle("PrimaryRegular", "Base"),
    },
    xl: {
      ...typeForStyle("PrimaryRegular", "Xl"),
    },
    "2xl": {
      ...typeForStyle("PrimaryRegular", "_2xl"),
    },
    "3xl": {
      ...typeForStyle("PrimaryRegular", "_3xl"),
    },
  },
  variants: {
    error: {
      color: ColourAtoms.BB.Pink,
    },
    secondaryXs: {
      ...typeForStyle("Secondary", "Xs"),
    },
    secondaryBase: {
      ...typeForStyle("Secondary", "Base"),
    },
    secondaryXl: {
      ...typeForStyle("Secondary", "Xl"),
    },
    secondary4xl: {
      ...typeForStyle("Secondary", "_4xl"),
    },
  },
};
export default Text;
