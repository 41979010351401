import { typeForStyle } from "../../DesignSystem";

const size = {
  lg: {
    ...typeForStyle("PrimaryMedium", "Lg"),
  },

  md: {
    ...typeForStyle("PrimaryMedium", "Base"),
  },

  sm: {
    ...typeForStyle("PrimaryMedium", "Sm"),
  },

  xs: {
    ...typeForStyle("PrimaryMedium", "Xs"),
  },
};

const Input = {
  sizes: {
    lg: {
      field: size.lg,
      addon: size.lg,
    },
    md: {
      field: size.md,
      addon: size.md,
    },
    sm: {
      field: size.sm,
      addon: size.sm,
    },
    xs: {
      field: size.xs,
      addon: size.xs,
    },
  },
};
export default Input;
