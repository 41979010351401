import { ColourAtoms, typeForStyle } from "../../DesignSystem";

const Form = {
  baseStyle: {
    helperText: {
      ...typeForStyle("PrimaryRegular", "Sm"),
      color: ColourAtoms.Raw.Gray._400,
      marginBottom: 2,
    },
  },
};
export default Form;
