import { transparentize } from "@chakra-ui/theme-tools";

import { ColourAtoms, typeForStyle } from "../../DesignSystem";

const base = typeForStyle("Secondary", "Base");
const Button = {
  baseStyle: {
    ...base,
    borderRadius: 5,
  },
  sizes: {
    base: {
      ...base,
      h: 12,
      minW: 12,
      px: 4,
    },
    md: {
      ...base,
    },
  },
  variants: {
    actived: {
      color: ColourAtoms.BB.Pink,
      bg: transparentize(ColourAtoms.BB.Pink, 0.1),
    },
  },

  defaultProps: {
    size: "base",
    colorScheme: "cerise",
  },
};
export default Button;
