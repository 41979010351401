import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

import Button from "./theme/components/button";
import Checkbox from "./theme/components/checkbox";
import Divider from "./theme/components/divider";
import Form from "./theme/components/form";
import FormError from "./theme/components/formError";
import FormLabel from "./theme/components/formLabel";
import Input from "./theme/components/input";
import Link from "./theme/components/link";
import List from "./theme/components/list";
import Radio from "./theme/components/radio";
import Select from "./theme/components/select";
import Spinner from "./theme/components/spinner";
import Text from "./theme/components/text";
import Tooltip from "./theme/components/tooltip";
import colors from "./theme/foundations/colors";
import styles from "./theme/styles";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
});

export const theme = extendTheme({
  styles,
  colors,
  breakpoints,
  sizes: {
    "128": "32rem",
    "136": "34rem",
  },
  components: {
    Button,
    Checkbox,
    Divider,
    Form,
    FormError,
    FormLabel,
    Input,
    Link,
    List,
    Radio,
    Select,
    Spinner,
    Text,
    Tooltip,
  },
});
