import { typeForStyle } from "../../DesignSystem";

const List = {
  baseStyle: {
    icon: {
      marginEnd: "0.2rem",
    },
  },
  variants: {
    secondary: { container: { ...typeForStyle("Secondary", "Base") } },
  },
};
export default List;
