import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { useMemo } from "react";

type Props = {
  initialState?: NormalizedCacheObject;
  token?: string;
};

function createApolloClient(token?: string) {
  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_PLATFORM_GRAPHQL_API,
    fetch,
  });
  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: token ? `JWT ${token}` : "",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach((graphQLError) => {
        Sentry.setExtra("operation", operation);
        Sentry.captureMessage(`[GraphQL Error] ${graphQLError.message}`);
      });
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined", // set to true for SSR
    link: from([errorLink, authLink.concat(httpLink)]),

    cache: new InMemoryCache({
      typePolicies: {
        UpcomingInvoice: {
          keyFields: ["id", "description"],
        },
      },
    }),
  });
}

export function getApolloClient(props?: Props) {
  const _apolloClient = createApolloClient(props?.token);

  if (props?.initialState) {
    const existingCache = _apolloClient.extract();

    _apolloClient.cache.restore({ ...props.initialState, ...existingCache });
  }

  return _apolloClient;
}

export const useApolloClient = ({
  initialState,
  token,
}: {
  initialState?: NormalizedCacheObject;
  token?: string;
}) => {
  const store = useMemo(
    () => getApolloClient({ initialState, token }),
    [initialState, token]
  );
  return store;
};
