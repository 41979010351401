import { AnalyticsBrowser } from "@segment/analytics-next";
import Router, { useRouter } from "next/router";
import { useSession } from "next-auth/client";
import { createContext, FC, useContext, useEffect, useMemo } from "react";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const SegmentContext = createContext<AnalyticsBrowser>(undefined!);

export const SegmentUserTracking: FC<{ writeKey: string }> = ({
  children,
  writeKey,
}) => {
  const [session] = useSession();
  const { asPath } = useRouter();
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey }, { initialPageview: true }),
    [writeKey]
  );

  const user = session?.user;
  if (user) {
    console.debug(`[SEGMENT] Identifying user ${user.id}`);
    analytics.identify(`${user.id}`, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const pathname = url.split("?")[0];
      console.debug(`[SEGMENT] Logging changed page ${pathname}`);
      analytics.page();
    };

    Router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [analytics, asPath]);

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  );
};

export const useSegment = () => {
  const result = useContext(SegmentContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};
