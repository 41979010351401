function ErrorFallback({
  error,
  componentStack,
}: {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}) {
  return (
    <div>
      <h2>Something went wrong.</h2>
      <details style={{ whiteSpace: "pre-wrap" }}>
        {error && error.toString()}
        <br />
        {componentStack}
      </details>
    </div>
  );
}
export default ErrorFallback;
